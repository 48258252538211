/* =================================== */
/*  Extras
/* =================================== */

/* Bootstrap Specific */

.form-control:not(.form-control-sm) {
  padding: .810rem .96rem;
  height:inherit;
  box-shadow: inset 0 0;
}

.form-control-sm{font-size:14px;}

.icon-inside {
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
  pointer-events: none;
  font-size: 18px;
  font-size: 1.125rem;
  color: #c4c3c3;
  z-index:3;
}
.form-control-sm + .icon-inside {
  font-size: 0.875rem !important;
  font-size: 14px;
  top: calc(50% - 13px);
}

select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
  height: auto;
  padding-top: .700rem;
  padding-bottom: .700rem;
}
.custom-select:not(.custom-select-sm){
  height:calc(3.05rem + 2px);
  padding-top: .700rem;
  padding-bottom: .700rem;}
.col-form-label-sm{font-size:13px;}
.custom-select-sm{padding-left:5px!important; font-size:14px;}
.custom-select:not(.custom-select-sm).border-0{height:3.00rem;}

.form-control:focus, .custom-select:focus{
  @include box-shadow(0 0 5px rgba(128, 189, 255, 0.5));
}
.form-control:focus[readonly]{box-shadow:none;}

.input-group-text {
  border-color: #dae1e3;
  background-color:#f1f5f6;
  color: #656565;
}

.form-control {
  &::-webkit-input-placeholder {
    color: #b1b4b6;
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: #b1b4b6;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: #b1b4b6;
  }
  &:-ms-input-placeholder, &::-ms-input-placeholder {
    /* IE 10+ */
    color: #b1b4b6;
  }
}

/* Form Dark */
.form-dark {
  .form-control, .custom-select {
	border-color: #232a31;
	background:#232a31;
	color: #fff;
  }
  .form-control:focus{border-color: #80bdff!important;}
  .form-control {
	&::-webkit-input-placeholder {
	  color: #777b7f;
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: #777b7f;
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: #777b7f;
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: #777b7f;
	}
  }
  .custom-select {
    color: #777b7f;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 13px 15px;
	border-color: #232a31;
	background-color:#232a31;
  }
  .icon-inside {
	color: #777b7f;
  }
}

/*  Input with only bottom border  */
.form-border {
  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding-left: 0px!important;
    color: rgba(0, 0, 0, 1);
	&::-webkit-input-placeholder {
	  color: rgba(0, 0, 0, 0.4);
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: rgba(0, 0, 0, 0.4);
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: rgba(0, 0, 0, 0.4);
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: rgba(0, 0, 0, 0.4);
	}
  }
  .custom-select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding-left: 0px;
    color: rgba(0, 0, 0, 0.4);
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(0,0,0,0.3)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 13px 15px;
  }
  .form-control:focus, .custom-select:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
	border-bottom: 2px solid rgba(0, 0, 0, 0.7);
  }
  .form-control:not(output):-moz-ui-invalid, .custom-select:not(output):-moz-ui-invalid {
    &:not(:focus), &:-moz-focusring:not(:focus) {
      border-bottom: 2px solid #b00708;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
  .icon-inside {
    color: rgba(0, 0, 0, 0.25);
  }
  select option {
    color: #666;
  }
}


.form-border-light {
  .form-control {
	border-bottom: 2px solid rgba(250, 250, 250, 0.3);
	color: rgba(250, 250, 250, 1);
	&::-webkit-input-placeholder {
	  color: rgba(250, 250, 250, 0.7);
	}
	&:-moz-placeholder {
	  /* FF 4-18 */
	  color: rgba(250, 250, 250, 0.7);
	}
	&::-moz-placeholder {
	  /* FF 19+ */
	  color: rgba(250, 250, 250, 0.7);
	}
	&:-ms-input-placeholder, &::-ms-input-placeholder {
	  /* IE 10+ */
	  color: rgba(250, 250, 250, 0.7);
	}
  }
  .custom-select {
    border-bottom: 2px solid rgba(250, 250, 250, 0.3);
    color: rgba(250, 250, 250, 1);
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.6)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 13px 15px;
  }
  .form-control:focus, .custom-select:focus {
	border-bottom: 2px solid rgba(250, 250, 250, 0.8);
  }
  .icon-inside {
    color: #999;
  }
  select option {
    color: #333;
  }
}


.btn {
  padding:0.8rem 2.6rem;
  font-weight:500;
  border-width:2px;
  @include transition(all .5s ease);
}

.btn-sm {
  padding: 0.5rem 1rem;
}

.btn:not(.btn-link) {
  @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
  &:hover {
	@include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.3));
	@include transition(all .5s ease);
  }
}

.input-group-append .btn, .input-group-prepend .btn {
  @include box-shadow(none);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.input-group-append .btn:hover, .input-group-prepend .btn:hover {
  @include box-shadow(none);
}

@include media-breakpoint-down(xs) {
  .btn:not(.btn-sm){
    padding: .810rem 2rem;
  }
  .input-group > {
  .input-group-append > .btn, .input-group-prepend > .btn {
    padding: 0 0.75rem;
  }
}
}


.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--#{$prefix}themecolor-rgb),var(--bs-bg-opacity,1)) !important;
}

.btn-primary{
    --#{$prefix}btn-color: var(--#{$prefix}white);
	--#{$prefix}btn-bg: var(--#{$prefix}themecolor);
	--#{$prefix}btn-border-color: var(--#{$prefix}themecolor);
	
    --#{$prefix}btn-hover-color: var(--bs-white);
	--#{$prefix}btn-hover-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}themehovercolor);
	
	--#{$prefix}btn-active-color: var(--bs-btn-hover-color);
	--#{$prefix}btn-active-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-active-border-color: var(--#{$prefix}themehovercolor);
}

.btn-outline-primary{
    --#{$prefix}btn-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-border-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-hover-bg: var(--#{$prefix}themecolor);
	--#{$prefix}btn-hover-color: var(--bs-white);
	--#{$prefix}btn-hover-border-color: var(--#{$prefix}themecolor);
	--#{$prefix}btn-active-color: var(--bs-btn-hover-color);
	--#{$prefix}btn-active-bg: var(--#{$prefix}themehovercolor);
	--#{$prefix}btn-active-border-color: var(--#{$prefix}themehovercolor);
}


/* Pagination */

.page-link {
  border: none;
  border-radius: 0.25rem;
  margin: 0 0.22rem;
  font-size: 16px;
  font-size: 1rem;
  &:hover {
    background-color: #e9eff0;
  }
}

/* styles-switcher */
#styles-switcher {
  background: #fff;
  width: 204px;
  position: fixed;
  top: 35%;
  z-index: 99;
  padding: 20px;
  left: -204px;
  ul {
    padding: 0;
    li {
      list-style-type: none;
      width: 25px;
      height: 25px;
      margin: 4px 2px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      transition: all .2s ease-in-out;
      &.blue {
        background: #007bff;
      }
      &.brown {
        background: #795548;
      }
      &.purple {
        background: #6f42c1;
      }
      &.indigo {
        background: #6610f2;
      }
      &.red {
        background: #dc3545;
      }
      &.orange {
        background: #fd7e14;
      }
      &.yellow {
        background: #ffc107;
      }
      &.green {
        background: #28a745;
      }
	  &.pink {
        background: #e83e8c;
      }
      &.teal {
        background: #20c997;
      }
      &.cyan {
        background: #17a2b8;
      }
      &.active {
        transform: scale(0.7);
		cursor:default;
      }
    }
  }
  .switcher-toggle {
    position: absolute;
    background: #333;
    color: #fff;
    font-size: 1.25rem;
    border-radius: 0px 4px 4px 0;
    right: -40px;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
  }
}

input:-internal-autofill-selected {
    background-color: transparent;
}

#styles-switcher.right{left:auto; right: -204px;}
#styles-switcher.right .switcher-toggle{right: auto; left: -40px; border-radius: 4px 0px 0px 4px;}