/* =================================== */
/*  3. Layouts
/* =================================== */

#main-wrapper {
  &.boxed {
    max-width: 1170px;
    margin: 0 auto;
	@include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  }
}

.section {
  position: relative;
  padding: 104px 0;
  padding: 6.5rem 0;
}

@include media-breakpoint-down(sm) {
	.section {
		padding: 3.5rem 0;
	}
}


/*== Fullscreen Height for Intor banner ==*/
.fullscreen{
	min-height: 100vh!important;
}

/*== Fullscreen Height for Intor banner with Header ==*/
.fullscreen-with-header{
	min-height: calc(100vh - 67px)!important;
}

/*== Heading Separator Line ==*/
.heading-separator-line{
	width:80px;
	height:2px!important;
	border-top:0px;
}