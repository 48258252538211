.mobile-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem 0;
  }
  
  @media (max-width: 992px) {
    .nav-link.d-lg-inline {
      display: none; /* Oculta el enlace en móviles */
    }
  
    .mobile-title {
      display: block; /* Muestra el título en móviles */
    }
  }
  
  @media (min-width: 992px) {
    .mobile-title {
      display: none; /* Oculta el título en escritorio */
    }
  }