/* =================================== */
/*  1. Basic Style 
/* =================================== */

body, html {
height:100%;
}

/*-------- Preloader --------*/
.preloader {
  position: fixed;
  z-index: 999999999 !important;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  [data-loader="dual-ring"]{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-left: -20px;
	margin-top: -20px;
	content: " ";
	display: block;
	border-radius: 50%;
	border: 5px solid var(--#{$prefix}themecolor);
	border-color: var(--#{$prefix}themecolor) #f1f5f6 var(--#{$prefix}themecolor) #f1f5f6;
	animation: dual-ring 0.8s linear infinite;
  }
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--- Preloader Magnific Popup ----*/
.mfp-container .preloader{
	background: transparent;
	[data-loader="dual-ring"]{
		border: 5px solid #fff;
		border-color: #fff transparent #fff transparent;
	}
}


form {
  padding: 0;
  margin: 0;
  display: inline;
}

img {
  vertical-align: inherit;
}

p {
  line-height: 1.9;
}

blockquote {
  border-left: 5px solid #eee;
  padding: 10px 20px;
}

iframe {
  border: 0 !important;
}